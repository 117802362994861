import logo from './logo.svg';
import './App.css';
import { Nav } from './components/Nav';
import Header from './components/Header';
import Train from './components/Train';
import How from './components/How';
import Class from './components/Class';
import Faq from './components/Faq';
import SubFooter from './components/SubFooter';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <Nav/>
      <Header/>
      <Train/>
      <How/>
      <Class/>
      <Faq/>
      <SubFooter/>
      <Footer/>
    </div>
  );
}

export default App;
