import React from 'react'
import Testimonials from './Testimonials'

function Header() {
  return (
    <>
        <div className='min-h-[65vh] bg-no-repeat bg-cover flex flex-col items-center justify-center' style={{background: 'url(/images/header.png)', backgroundSize: 'cover'}}>
            <div className='flex flex-col mt-[10%] px-4 xl:flex-row justify-between pt-6 lg:pt-0 max-w-[90%] mx-auto'>
                <div className='flex flex-col items-center lg:items-start space-y-[40px]'>
                    <h1 className='text-[40px] lg:max-w-[530px] 2xl:text-[52px] text-black font-extrabold text-center lg:text-left leading-tight'>Be <span className='text-[#1A5DC3]'>Empowered</span> to Protect Yourself and Others</h1>
                    <p className='text-[16px] max-w-[500px] 2xl:text-[20px] text-black/70 font-medium text-center lg:text-left'>An Israeli self defense training program to help you become confident, have strength and have the tools to do what you must</p>
                    <div className='flex flex-row items-center gap-x-[20px]'>
                        <a href="https://cincinnatikravmaga.gymmasteronline.com/portal/signup" className='bg-[#1A5DC3] rounded-[8px] px-4 2xl:w-[228px] h-[55px] flex flex-col items-center justify-center text-white'>$19 Intro Class</a>
                        <a href="https://cincinnatikravmaga.gymmasteronline.com/portal/signup" className='text-[19px] text-black/60'>Sign Up Today</a>
                    </div>
                </div>
                <img className=' xl:max-w-[580px]' src="/images/header-img.png"/>
            </div>
        </div>
        <Testimonials/>
    </>
  )
}

export default Header