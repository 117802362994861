
"use client";

import { Button, Navbar } from "flowbite-react";

export function Nav() {
  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="https://flowbite-react.com">
        <img src="/images/logo.png" className="mr-3 w-[96px]" alt="Flowbite React Logo" />
      </Navbar.Brand>
      <div className="flex md:order-2 list-none items-center">
      <Navbar.Link className="text-[19px] text-black/60 mr-8" href="https://cincinnatikravmaga.gymmasteronline.com/portal/login">Student Login</Navbar.Link>
        <a href="https://cincinnatikravmaga.gymmasteronline.com/portal/signup"><Button className="bg-[#1A5DC3]">$19 Intro Class</Button></a>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link className="text-[19px] text-black" href="#class">Class Schedule</Navbar.Link>
        <Navbar.Link className="text-[19px] text-black" href="#faq">FAQ</Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}
