import React from 'react'

function Testimonials() {
  return (

    <div class="marquee md:mt-0 mt-[-3em]  bg-[#1A5DC3] min-w-screen h-[90px] w-screen overflow-hidden flex flex-row items-center justify-center rotate-[-2deg] gap-x-[28px] text-white text-[20px]">
  <ul class="marquee__content">
    <li>“Great Facility! Every interaction I've had with the owner is courteous, respectful, and direct. Would recommend checking this place out for self-defense training!”</li>
    <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    <li>“Excellent program for kids and adults. Get in shape and learn some self-defense at the same time.”</li>
    <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    <li>“Very welcoming for a newbie. Instructors seems to really know their stuff.”</li>
    <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    <li>“One of the best decisions I've made. It's simple, natural and effective. You won't regret getting started.”</li>
    <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    <li>“Great place! Instructors are great and so are the people.”</li>
    <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    <li>“Great people. Good exercise with self defense expertly taught. No pressure. I love it.”</li>
  </ul>
  <ul class="marquee__content" aria-hidden="true">
  <li>“Great Facility! Every interaction I've had with the owner is courteous, respectful, and direct. Would recommend checking this place out for self-defense training!”</li>
    <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    <li>“Excellent program for kids and adults. Get in shape and learn some self-defense at the same time.”</li>
    <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    <li>“Very welcoming for a newbie. Instructors seems to really know their stuff.”</li>
    <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    <li>“One of the best decisions I've made. It's simple, natural and effective. You won't regret getting started.”</li>
    <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    <li>“Great place! Instructors are great and so are the people.”</li>
    <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    <li>“Great people. Good exercise with self defense expertly taught. No pressure. I love it.”</li>
  </ul>
</div>

    // <div className='bg-[#1A5DC3] min-w-screen h-[90px] w-screen overflow-hidden flex flex-row items-center justify-center rotate-[-2deg] gap-x-[28px] text-white text-[20px] '>
    //     <p>“It’s the best thing I could have done for myself”</p>
    //     <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    //     <p>“100% Recommend Cincinnati Krav Maga!”</p>
    //     <div className='w-[10px] h-[10px] bg-white rounded-full'></div>
    //     <p>“The instructors are experts and you’ll be prepared!”</p>
    // </div>
  )
}

export default Testimonials