import React from "react";

function Train() {
  return (
    <div className="flex flex-col mx-auto items-center justify-center px-4 py-[62px]">
      <h6 className="text-black text-[40px] font-extrabold mb-[50px]">
        Who Trains At Cincinnati Krav Maga
      </h6>
      <div className="flex flex-col gap-y-[40px] lg:flex-row items-start justify-center gap-x-[100px] mb-[50px]">
        <div className="flex flex-col items-start gap-y-[24px] max-w-[282px]">
          <img src="/images/teens.png" />
          <p className="text-[18px] text-black text-left">
          Our Krav Maga program help teens stay physically active and build self confidence. Using drills to help practice our self defense techniques, students strengthen their balance and coordination...
            <details>
              <summary className="text-[#1A5DC3]">Click For More</summary>

              <p> And because our program involves punching and kicking, teens get to workout any stress they may have. <div className="my-2"></div>
Cincinnati Krav Maga’s training gives teens the skills they need to be able to protect themselves in their current school and college. <div className="my-2"></div> Learning defenses against punches, chokes, hand and hair grabs tend to become more important to college students these days. 
Cincinnati Krav Maga helps all students to prepare for the worse case scenarios in life, with the hope our students will never have to use these skills.
</p>
            </details>
          </p>
        </div>
        <div className="flex flex-col items-start gap-y-[24px] max-w-[282px]">
          <img src="/images/men.png" />
          <p className="text-[18px] text-black text-left">
          There is always a chance you will have to defend yourself. And if you are a gun owner, have you thought about what you would do if your gun isn’t accessible or...

            <details>
              <summary className="text-[#1A5DC3]">Click For More</summary>

              <p> if your attacker gets the gun from you?
Cincinnati Krav Maga’s program will teach you the best way to defend yourself.  From punches/kicks to knife threats/attacks and even gun threats, you will learn the most effective ways to defend.  <div className="my-2"></div>
Does Cincinnati Krav Maga have the answers to every possible attack in existence? No. <div className="my-2"></div> If someone wants to kill you, they will kill you. But, if someone is threatening you and giving you that small chance to save your life, we have options for you.
</p>
            </details>
          </p>
        </div>
        <div className="flex flex-col items-start gap-y-[24px] max-w-[282px]">
          <img src="/images/women.png" />
          <p className="text-[18px] text-black text-left">
          Women tend to be a target for attacks by males for one simple reason: lack of strength to fend for themselves. But, every person has a weakness on their...
            <details>
              <summary className="text-[#1A5DC3]">Click For More</summary>

              <p>body; no matter how much body building or strengthen a person does, those weak areas cannot get stronger. This is what Cincinnati Krav Maga focuses on. <div className="my-2"></div>
Our system of self defense is not pretty. We target the weak areas of our attacker: hit them, grab them, scratch and bite those areas. Whatever it takes to get the attacker off so we can run to safety. It’s all ugly! <div className="my-2"></div> The hard part for women is how to be OK with hurting someone.
Training techniques must be coupled with learning how to get the most power from your strikes and how to find your aggression during pressured situations. This is why it is impossible for someone to truly protect themselves after a 2-8 hours self defense seminar.</p>
            </details>
          </p>
        </div>
      </div>
      <div className='flex flex-row items-center gap-x-[20px]'>
        <a href="https://cincinnatikravmaga.gymmasteronline.com/portal/signup" className='bg-[#1A5DC3] rounded-[8px] px-4 2xl:w-[228px] h-[55px] flex flex-col items-center justify-center text-white'>Sign up for Intro Class</a>
        <p className='text-[19px] text-black/60'>Only $19</p>
      </div>
    </div>
  );
}

export default Train;
