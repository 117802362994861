import React from 'react'

function Class() {
  return (
    <div id="class" className="flex flex-col mx-auto items-center justify-center px-4 py-[62px]">
    <h6 className="text-black text-[40px] font-extrabold mb-[50px]">
    Our Class Schedule
    </h6>
    <div className='flex flex-row  justify-center gap-[35px] flex-wrap'>
        <img className='max-w-[278px]' src="/images/Mon.png"/>
        <img className='max-w-[278px]' src="/images/Tues.png"/>
        <img className='max-w-[278px]' src="/images/Wed.png"/>
        <img className='max-w-[278px]' src="/images/Thur.png"/>
        <img className='max-w-[278px]' src="/images/Fri.png"/>
        <img className='max-w-[278px]' src="/images/Sat.png"/>
        <img className='max-w-[278px]' src="/images/Sun.png"/>
    </div>
  </div>
  )
}

export default Class