import React from 'react'

function SubFooter() {
  return (
    <div className='relative'>
        <img className='bottom-0 left-0 absolute z-0 max-h-[380px]' src="/images/blue.png"/>
    <div className='flex max-w-[1300px] mx-auto flex-col-reverse px-4 xl:flex-row justify-between pt-[80px] mx-auto'>
    <img className=' xl:max-w-[40%] relative z-10' src="/images/header-img.png"/>
        <div className='flex flex-col  item-center space-y-[40px] pb-[80px]'>
            <h6 className='text-[40px] lg:max-w-[530px] 2xl:text-[40px] text-black font-extrabold text-center'>Ready to become empowered with Krav Maga</h6>
            <p className='text-[16px] max-w-[500px] 2xl:text-[20px] text-black/70 font-medium text-center'>An Israeli self defense training program to help you become confident</p>
            <div className='flex flex-row items-center justify-center mx-auto gap-x-[20px]'>
                <a href="https://cincinnatikravmaga.gymmasteronline.com/portal/signup" className='bg-[#1A5DC3] rounded-[8px] px-4 2xl:w-[228px] h-[55px] flex flex-col items-center justify-center text-white'>$19 Intro Class</a>
                <p className='text-[19px] text-black/60'>Sign Up Today</p>
            </div>
        </div>
    </div>
</div>
  )
}

export default SubFooter