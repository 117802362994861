import React from 'react'
import { Accordion } from "flowbite-react";


function Faq() {
  return (
    <div id="faq" className="flex bg-[#F6F6F6] relative flex-col mx-auto items-center justify-center px-4 py-[62px]">
        <img className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0' src="/images/faq-bg.png"/>
    <h6 className="text-black text-[40px] font-extrabold mb-[50px]">
    Frequently Asked Questions
    </h6>
    <div className='flex flex-col gap-y-[14px] relative z-10 w-full max-w-[1000px] mx-auto'>
    <Accordion collapseAll className='w-full gap-y-[14px] bg-[#E8E8E8]/60'>
      <Accordion.Panel className='mb-[14px] '>
        <Accordion.Title> What is the cost of our program? </Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400 text-left">
          While we know this is an important question, we think it is more important to know if you like the program. No reason to discuss price if you hate the program. During the intro lesson we will discuss the different options for pricing. Just know this, if you want to train at Cincinnati Krav Maga, we will help you make it happen
          </p>

        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
    <Accordion collapseAll className='w-full gap-y-[14px] bg-[#E8E8E8]/60'>
      <Accordion.Panel className='mb-[14px] bg-[#E8E8E8]/60'>
        <Accordion.Title>What is the class schedule?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400 text-left">
          Intro Lessons: M @ 5:30-7pm and T @ noon-1pm or 6:30pm-8pm
Beginner's Classes: M&W @ 6pm and T&Th @ noon or 7pm and Sun @ noon
Advanced Level Classes: M&W @ 7pm and T&TH @ noon or 8pm and Sun @ 1pm
No classes on Fridays and Saturdays 
          </p>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
    <Accordion collapseAll className='w-full gap-y-[14px] bg-[#E8E8E8]/60'>
      <Accordion.Panel className='mb-[14px] bg-[#E8E8E8]/60'>
        <Accordion.Title>Is your system a belt system or a level system?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400 text-left">
          Our Krav Maga program is a belt and level system. The levels are: Beginner’s Level and Advanced Level. The belt ranking is: White, Yellow, Orange, Green, Blue, Brown and Black. While the belts are good to recognize what a student has achieved, the emphasis is really about what you are learning. While we want students to train to get their Black Belts, we would rather see a person train hard what they have learned and not focus on the belts. The Beginner’s Level is the real starting point and gives you the opportunity to see if this is the right program for you; it is your orientation, if you will. During this level, which is typically 4 months, a student will get the foundation they need to train the rest of the levels. Topics covered in this level are: punches, kicks, stances, punch defenses, kick defenses, releasing from chokes and wrist grab, and how to fall properly. The upper levels are exclusive to our students who pass the Yellow Belt test and are invited to Advanced Level. This is our main program, and we only allow students who show control and desire to come to this level. During these upper levels, topics cover: punches, kicks, punch defenses, kick defenses, releases from chokes/bearhugs/hair grabs/ground attacks, defenses against knife threats/attacks, defenses against stick attacks, defenses against gun threats, and more options to rolls and breaking falls.
          </p>

        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
    <Accordion collapseAll  className='w-full gap-y-[14px] bg-[#E8E8E8]/60'>
      <Accordion.Panel className='mb-[14px] '>
        <Accordion.Title>.How often do students attend?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400 text-left">
          Students attend classes 2-3 times a week and train the same topic throughout the week. This method is best because the first class a student attends they learn the techniques, then the second time the student can fine tune the technique and start working it harder. And, if the student comes a third time, then he/she can train it really hard and stop thinking about the details as much. And because our program is on a rotating curriculum, students train each topic 2-4 times a year.
          </p>

        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
    <Accordion collapseAll className='w-full gap-y-[14px] bg-[#E8E8E8]/60'>
      <Accordion.Panel className='mb-[14px] bg-[#E8E8E8]/60'>
        <Accordion.Title>Can out of shape, physically challenged and/or older people train?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400 text-left">
          Yes! Anyone can be attacked, therefore anyone can train Krav Maga! 
          </p>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
    <Accordion collapseAll className='w-full gap-y-[14px] bg-[#E8E8E8]/60'>
      <Accordion.Panel className='mb-[14px] bg-[#E8E8E8]/60'>
        <Accordion.Title>Can I stop in to observe a class?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400 text-left">

The only way to know if you will like our training is to try it, not watch it. This is why we offer our $19 intro lesson. Please do not stop in to watch a class, come experience it!

          </p>

        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
    <Accordion collapseAll className='w-full gap-y-[14px] bg-[#E8E8E8]/60'>
      <Accordion.Panel className='mb-[14px] bg-[#E8E8E8]/60'>
        <Accordion.Title> What is the history of Krav Maga?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400 text-left">

          Krav Maga, which is Hebrew for “contact combat”, is a self defense method originally designed for the Israel Defense Forces and is now developed for civilian self defense. It is not meant for competitions or as a sport; it is simple, natural and effective self defense. <div className='my-2'></div>

Imi Lichtenfeld was a Hungarian-born Israeli martial artist who founded the Krav Maga self-defense system. He was also known as ImiSde-Or, the Hebrew calque of his surname. Born on May 26, 1910, to a Hungarian Jewish family. He grew up in Bratislava, Slovakia. Lichtenfeld trained at the Hercules Gymnasium, which was owned by his father, who taught self-defense. Lichtenfeld was a successful boxer, wrestler, and gymnast since his youth. As anti-semitism rose, he started fighting and found sport isn’t good for real combat and starting developing a system of techniques. In 1942 he headed for Palestine and in 1944 began training fighters. In 1948, Imi became the Chief Instructor for Physcial Fitness and Krav Maga at the IDF School of Combat Fitness. <div className='my-2'></div>

Eli Avikzar began training with Imi Lichtenfeld (Sde-Or) in November 1964 at his institute in Netanya, and became his principal student. In 1965 Imi added Judo training, as part of the Krav Maga training, and later Karate and Jujitsu. Eli would then return regularly to Imi's training classes and together they improved and developed counter exercises to Judo, Karate and Jujitsu. In 1968 Eli Avikzar began learning Aikido. Eli left for France in 1971, passed the test and received a brown belt in Aikido. Prior to his journey, Eli received his Black belt in Judo, in addition to the first Black belt ever given in Krav Maga, which he received from Imi on May 1, 1971. Upon his return, Eli started working as an instructor alongside Imi in both Netanya and Tel-Aviv training centers. In addition, they trained special army and volunteer units. <div className='my-2'></div>

In 1980 Guy Dar began training Krav Maga with the Israeli Krav Magen Schools of Martial Arts. He was one the highest-ranking students of the late master, Mr. Eli Avikzar (Bless His Memory). Mr. Dar trained Cincinnati Krav Maga instructors from 2007 – 2018. <div className='my-2'></div>

          </p>

        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
    <Accordion collapseAll className='w-full gap-y-[14px] bg-[#E8E8E8]/60'>
      <Accordion.Panel className='mb-[14px] bg-[#E8E8E8]/60'>
        <Accordion.Title>What makes Krav Maga so different?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400 text-left">
          We’ve been training Krav Maga since birth, but we just never realized it! The main principle of this system of self defense is to use our natural reactions to protect ourselves. <div className='my-2'></div>

Think about sitting at a park and out of the corner of your eye you see something (most likely a ball or Frisbee) headed right at your head and it’s about to hit you. Even a small child knows to put their hands up to protect their head. This is Krav Maga! <div className='my-2'></div>

You have a natural reaction to attacks, some good reactions and some not so good. We take the good reactions and train them so your brain knows they are good. And we ignore the bad reactions and give you better options to replace them with. However, the only wrong reaction is no reaction. Most training programs say there is a right way to do things and a wrong way. Cincinnati Krav Maga thinks there are good ways to protect yourself and there are better ways. Doing anything for protection is good, but we have other options to protect yourself better! <div className='my-2'></div>

Krav Maga is about you learning different ways to protect yourself and how to attack back. It is about preparing yourself for the sad reality of being attacked. We do a strong fitness workout to begin class, not just for fitness (although that is a plus), but to get your body and mind in a similar state of being attacked. This way you are training self defense in a similar mindset. <div className='my-2'></div>

          </p>

        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
    </div>
  </div>
  )
}

export default Faq